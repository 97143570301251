import React from "react";
// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import MuiTable from "@mui/material/Table";
// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { TableBody, TableContainer, TableRow, Zoom } from "@mui/material";
import MKInput from "components/MKInput";
import { fnumber } from "helper/Helper";
import Config from "config";
import secureStorage from "libraries/secureStorage";

import GSearchActions from "contents/components/GSearchActions";

import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import moment from "moment";

export default class Reprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      buttonDisabled: true,
      customerCode: "",
      isLoaded: false,
      rows: [],

      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
    };
  }

  loadHistory(params) {
    this.setState({ isLoaded: true });
    const payload = {
      offset: 0,
      limit: 10,
      customerCode: this.state.customerCode,
      startDate: params && params.startDate ? params.startDate : this.state.startDate,
      endDate: params && params.endDate ? params.endDate : this.state.endDate,
    };

    axios
      .post(`${Config.EndPoint}/history/get`, payload)
      .then((res) => {
        this.setState({
          customerCode: "",
          rows: res.data,
          isLoaded: false,
          isActive: true,
        });
      })

      .catch((err) => {
        console.log(err);
      });
  }

  toggleModal = () => this.setShow(!this.state.show);

  setShow = (show) => {
    if (show) this.loadHistory();
    this.setState({ show });
  };

  getPeriod(rb) {
    if (rb.period) return rb.period.value;
    else return "";
  }

  render() {
    return (
      <Modal
        open={this.state.show}
        onClose={this.toggleModal}
        sx={{ display: "grid", placeItems: "center" }}
      >
        <Zoom in={this.state.show}>
          <MKBox
            position="relative"
            width="90%"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
            style={{ outline: "none" }}
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Cetak Ulang</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={this.toggleModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox pl={5} pr={5}>
              <MKBox mb={1}>
                <MKInput
                  inputRef={this.inputPaymentRef}
                  fullWidth
                  inputProps={{ style: { textAlign: "right", fontSize: 20 } }}
                  label="IDPEL"
                  value={this.state.customerCode}
                  onChange={(e) => {
                    this.setState({ customerCode: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.loadHistory();
                    }
                  }}
                />
              </MKBox>
              {this.state.isLoaded ? (
                <MKBox display="flex" justifyContent="center" m={3}>
                  <CircularProgress color="inherit" />
                </MKBox>
              ) : (
                <TableContainer
                  sx={{
                    borderRadius: 2,
                  }}
                >
                  <MuiTable>
                    <TableBody>
                      {this.state.rows.map((item, index) => (
                        <TableRow key={uuidv4()}>
                          {/* CustomerCode */}
                          <MKBox
                            component="td"
                            p={0.5}
                            textAlign="left"
                            sx={{ backgroundColor: index % 2 === 0 ? "#8cb4f5" : "#cad7ed" }}
                          >
                            <MKTypography
                              variant="button"
                              fontWeight="bold"
                              // color="secondary"
                              sx={{ display: "inline-block", width: "max-content" }}
                            >
                              {item.customerCode}
                            </MKTypography>
                          </MKBox>
                          {/* Produt Name */}
                          <MKBox
                            component="td"
                            p={0.5}
                            textAlign="left"
                            sx={{ backgroundColor: index % 2 === 0 ? "#8cb4f5" : "#cad7ed" }}
                          >
                            <MKTypography
                              variant="button"
                              fontWeight="bold"
                              // color="secondary"
                              sx={{ display: "inline-block", width: "max-content" }}
                            >
                              {item.productName}
                            </MKTypography>
                          </MKBox>
                          {/* Date */}
                          <MKBox
                            component="td"
                            p={0.5}
                            textAlign="left"
                            sx={{ backgroundColor: index % 2 === 0 ? "#8cb4f5" : "#cad7ed" }}
                          >
                            <MKTypography
                              variant="button"
                              fontWeight="bold"
                              // color="secondary"
                              sx={{ display: "inline-block", width: "max-content" }}
                            >
                              {moment(item.createdAt ? item.createdAt : item.updatedAt).format(
                                "DD MMM YYYY HH:mm:ss"
                              )}
                            </MKTypography>
                          </MKBox>
                          {/* Period */}
                          <MKBox
                            component="td"
                            p={0.5}
                            textAlign="center"
                            sx={{ backgroundColor: index % 2 === 0 ? "#8cb4f5" : "#cad7ed" }}
                          >
                            <MKTypography
                              variant="button"
                              fontWeight="bold"
                              // color="secondary"
                              sx={{ display: "inline-block", width: "max-content" }}
                            >
                              {this.getPeriod(item.receiptBody)}
                            </MKTypography>
                          </MKBox>
                          {/* Amount */}
                          <MKBox
                            component="td"
                            p={0.5}
                            textAlign="right"
                            sx={{ backgroundColor: index % 2 === 0 ? "#8cb4f5" : "#cad7ed" }}
                          >
                            <MKTypography
                              variant="button"
                              fontWeight="bold"
                              // color="secondary"
                              sx={{ display: "inline-block", width: "max-content" }}
                            >
                              {fnumber(item.amount)}
                            </MKTypography>
                          </MKBox>
                          {/* Button View */}
                          <MKBox
                            component="td"
                            p={0.5}
                            textAlign="right"
                            sx={{ backgroundColor: index % 2 === 0 ? "#8cb4f5" : "#cad7ed" }}
                          >
                            <MKTypography
                              variant="button"
                              fontWeight="bold"
                              // color="secondary"
                              sx={{ display: "inline-block", width: "max-content" }}
                            >
                              <MKButton
                                circular
                                fullWidth
                                color="info"
                                variant="gradient"
                                size="small"
                                width="100%"
                                onClick={() => {
                                  this.props.onPreview(item);
                                }}
                              >
                                view
                              </MKButton>
                            </MKTypography>
                          </MKBox>
                          {/* Button Print */}
                          <MKBox
                            component="td"
                            p={0.5}
                            textAlign="right"
                            sx={{ backgroundColor: index % 2 === 0 ? "#8cb4f5" : "#cad7ed" }}
                          >
                            <MKTypography
                              variant="button"
                              fontWeight="bold"
                              // color="secondary"
                              sx={{ display: "inline-block", width: "max-content" }}
                            >
                              <MKButton
                                circular
                                fullWidth
                                color="primary"
                                variant="gradient"
                                size="small"
                                width="100%"
                                onClick={() => {
                                  this.props.onConfirm(item);
                                  this.setState({ show: false });
                                }}
                              >
                                cetak
                              </MKButton>
                            </MKTypography>
                          </MKBox>
                        </TableRow>
                      ))}
                    </TableBody>
                  </MuiTable>
                </TableContainer>
              )}
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="error" onClick={this.toggleModal}>
                batal
              </MKButton>
            </MKBox>
          </MKBox>
        </Zoom>
      </Modal>
    );
  }
}
